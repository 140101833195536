<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="上级公司名称" prop="parentId">
          <el-select
            v-model="dataForm.parentId"
            :disabled="disabled"
            placeholder="上级公司名称"
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="公司名称" prop="name">
          <el-input
            v-model="dataForm.name"
            :disabled="disabled"
            placeholder="公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司地址" prop="address">
          <el-input
            v-model="dataForm.address"
            :disabled="disabled"
            placeholder="具体到楼层"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否记账" prop="isMark">
          <el-radio-group v-model="dataForm.isMark" :disabled="disabled">
            <el-radio :label="0">不记账</el-radio>
            <el-radio :label="1">记账</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="折扣率" prop="discountRate">
          <el-input
            v-model="dataForm.discountRate"
            :disabled="disabled"
            placeholder="输入98代表98%"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        parentId: '',
        name: '',
        parentCompanyName: '',
        address: '',
        isMark: '',
        discountRate: '',
        leftMoney: '',
        list: [],
      },
      companyList: [],
      dataRule: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.getCompany();
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/company/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = data.company;
            }
          });
        }
      });
    },
    getCompany() {
      this.$http({
        url: '/tc/company/combo',
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          /*if (data.list[0].parentId === null) {
            this.disabled = false;
          }*/
          this.companyList = data.list;
        } else {
          this.companyList = [];
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/company/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            }
          });
        }
      });
    },
  },
};
</script>
